var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-container",
        [
          _c(
            "div",
            [
              _c(
                "el-row",
                {
                  staticStyle: {
                    padding: "14px 12px",
                    background: "#FFFFFF",
                    position: "relative",
                  },
                },
                [
                  _c("el-input", {
                    staticClass: "searchInput",
                    class: _vm.popoverShow ? "inputWidth" : "",
                    attrs: {
                      size: "small",
                      placeholder: _vm.$t(
                        "cip.cmn.msg.warning.filterateWarning"
                      ),
                    },
                    model: {
                      value: _vm.filterText,
                      callback: function ($$v) {
                        _vm.filterText = $$v
                      },
                      expression: "filterText",
                    },
                  }),
                ],
                1
              ),
              _c("el-tree", {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                ref: "commonTree",
                staticStyle: {
                  "font-size": "12px",
                  "border-radius": "4px",
                  padding: "0 5px 0 12px",
                },
                attrs: {
                  data: _vm.treeData,
                  props: _vm.defaultProps,
                  "default-expand-all": true,
                  "highlight-current": true,
                  "expand-on-click-node": false,
                  "check-strictly": true,
                  "show-checkbox": true,
                  "node-key": "id",
                  "filter-node-method": _vm.filterNode,
                },
                on: {
                  "node-click": _vm.nodeClick,
                  "check-change": _vm.handleCheckChange,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ node, data }) {
                      return _c(
                        "span",
                        { staticStyle: { display: "flex" } },
                        [
                          data.children
                            ? _c("i", {
                                staticClass: "el-icon-folder",
                                staticStyle: { "line-height": "28px" },
                              })
                            : data.category == 1
                            ? _c("i", {
                                staticClass: "el-icon-folder",
                                staticStyle: { "line-height": "28px" },
                              })
                            : data.category == "all"
                            ? _c("i", {
                                staticClass: "el-icon-star-off",
                                staticStyle: { "line-height": "28px" },
                              })
                            : _c("i", {
                                staticClass: "el-icon-document",
                                staticStyle: { "line-height": "28px" },
                              }),
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: node.label,
                                placement: "bottom",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "margin-left": "10px",
                                    "white-space": "nowrap",
                                    overflow: "hidden",
                                    "text-overflow": "ellipsis",
                                    width: "200px",
                                    display: "block",
                                  },
                                },
                                [_vm._v(_vm._s(node.label))]
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "el-container",
            [
              _c(
                "el-main",
                [
                  _c("head-layout", {
                    attrs: {
                      "head-title": _vm.$t(
                        "cip.plat.sys.user.title.indexHeadTitle"
                      ),
                    },
                  }),
                  _c("grid-head-layout", {
                    ref: "searchFrom",
                    attrs: {
                      "head-title": _vm.$t(
                        "cip.plat.sys.user.title.indexHeadTitle"
                      ),
                      "search-columns": _vm.searchColumns,
                    },
                    on: {
                      "grid-head-search": _vm.searchChange,
                      "grid-head-empty": _vm.searchReset,
                    },
                    model: {
                      value: _vm.searchForm,
                      callback: function ($$v) {
                        _vm.searchForm = $$v
                      },
                      expression: "searchForm",
                    },
                  }),
                  _c("grid-layout", {
                    ref: "gridLayout",
                    attrs: {
                      "head-title": _vm.$t(
                        "cip.plat.sys.user.title.indexHeadTitle"
                      ),
                      "data-total": _vm.page.total,
                      page: _vm.page,
                      tableOptions: _vm.tableOptions,
                      tableData: _vm.tableData,
                      tableLoading: _vm.tableLoading,
                      searchColumns: _vm.searchColumns,
                    },
                    on: {
                      "page-size-change": _vm.onLoad,
                      "page-current-change": _vm.onLoad,
                      "page-refresh-change": _vm.onLoad,
                      "gird-handle-select-click": _vm.selectionChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { display: "flex", "justify-content": "center" } },
        [
          _c(
            "el-button",
            {
              staticStyle: { "margin-right": "24px" },
              attrs: { size: "medium", type: "primary" },
              on: { click: _vm.confirm },
            },
            [_vm._v("确认")]
          ),
          _c(
            "el-button",
            { attrs: { size: "medium" }, on: { click: _vm.closeDialog } },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }