var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-container",
        [
          _c(
            "el-main",
            [
              _c("head-layout", {
                attrs: {
                  "head-title": "奖惩基础信息",
                  "head-btn-options": _vm.headTopBtnOptions,
                },
                on: {
                  "head-cancel": _vm.handleHeadGoback,
                  "head-save": function ($event) {
                    return _vm.headSave(1)
                  },
                  "head-save-back": function ($event) {
                    return _vm.headSave(2)
                  },
                },
              }),
              _c("form-layout", {
                ref: "formLayout",
                attrs: { column: _vm.formColumn, dataForm: _vm.dataForm },
                on: {
                  "update:dataForm": function ($event) {
                    _vm.dataForm = $event
                  },
                  "update:data-form": function ($event) {
                    _vm.dataForm = $event
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-container",
        [
          _c(
            "el-main",
            [
              _c("uploader", {
                ref: "uploader",
                attrs: {
                  fileSize: 500,
                  accept: ".jpg,.png,.pdf,.gif,.jpeg,.ppt,.doc,.docx,.xls",
                  multiple: true,
                  fileOptions: _vm.fileOptions,
                  gridRowBtn: _vm.fileGridRowBtn,
                  disabled: _vm.type == "view",
                },
                on: { getTableData: _vm.handleUpload },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.type !== "add"
        ? [
            _c(
              "el-container",
              [
                _c(
                  "el-main",
                  [
                    _c("head-layout", {
                      attrs: {
                        "head-title": "接受对象",
                        "head-btn-options": _vm.userBtnOptions,
                      },
                      on: { "head-add": _vm.handleUserAdd },
                    }),
                    _c("grid-head-layout", {
                      ref: "gridHeadLayout",
                      attrs: { "search-columns": _vm.searchColumns },
                      on: {
                        "grid-head-search": _vm.gridHeadSearch,
                        "grid-head-empty": _vm.gridHeadEmpty,
                      },
                    }),
                    _c("grid-layout", {
                      ref: "gridLayOut",
                      attrs: {
                        "grid-row-btn": _vm.gridRowBtn,
                        "table-options": _vm.tableOptions,
                        "table-data": _vm.tableData,
                        "table-loading": _vm.tableLoading,
                        "data-total": _vm.page.total,
                        page: _vm.page,
                        editType: _vm.inline,
                      },
                      on: {
                        "page-current-change": _vm.handleCurrentChange,
                        "page-size-change": _vm.handleSizeChange,
                        "page-refresh-change": _vm.onLoad,
                        "row-remove": _vm.rowUserRemove,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "接受对象选择",
            visible: _vm.deptShow,
            width: "80%",
            top: "8vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.deptShow = $event
            },
          },
        },
        [
          _c("UserDeptMultipleDialog", {
            ref: "UserDeptMultipleDialog",
            on: {
              "select-all-data": _vm.selectAllData,
              close: function ($event) {
                _vm.deptShow = false
              },
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }